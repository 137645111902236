<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getUnsellReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="unsellTitle" class="col-md-10 font-weight-normal text-dark">
              {{ $t('report.txtUnsell') }}
            </h2>
            <div class="col-md-2 text-right text-success">
              <CButton
                id="unsellExportButton"
                v-if="isExport"
                v-on:click="exportUnsellReport()"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              />
              <hr />
              <DataTable
                id="unsellDatatable"
                style="white-space:nowrap"
                :items="items"
                :fields="fields"
                hover
                border
                :loading="loading"
              >
              <template #onhandQty="{ item }">
                    <td v-if="item.onhandQty < 0.00" class="text-right text-danger">{{ item.onhandQty }}</td>
                    <td v-else class="text-right text-dark">{{ item.onhandQty }}</td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getUnsellReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      sortOrder: 'asc',
      sortdata: 'SKUName',
      sortnum: false,
      sort_value: {
        name: 'SKUName',
        category: 'category.name',
        sortByNumMin: 'sortByNumMin',
        sortByNumMax: 'sortByNumMax',
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata == 'sortByNumMin' || sortdata == 'sortByNumMax') {
          sortdata = 'stdCost'
        }

        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'ProductSKUStock.onhandQty'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:5%',
        },
        { key: 'PLUCode', label: this.$i18n.t('pluCode'), _style: 'width:10%', _classes: 'text-dark font-weight-normal',},
        {
          key: 'SKUName',
          label: this.$i18n.t('productName'),
          _style: 'width:45%',
          _classes: 'text-dark font-weight-normal'
        },
        { key: 'categoryName', label: this.$i18n.t('category'), _classes: 'text-dark font-weight-normal'},
        {
          key: 'stdCost',
          label: this.$i18n.t('stdCost'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:10%',
        },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:10%',
        },
      ]
    },
    items() {
      let data = this.data || []
      let detail = []
      let start = this.meta_data.current_page * 50 - 50
      let onhandQty = '-'
      let stdCost = '-'
      let PLUCode = '-'

      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (item.ProductSKUStock.length != 0) {
          if (item.ProductSKUStock[0].onhandQty !== undefined) {
            onhandQty = util.convertOnhand(item.ProductSKUStock[0].onhandQty)
          }

          if (item.stdCost != undefined && item.stdCost != null) {
            stdCost = data[i].stdCost
          }
        }

        if (item.ProductPLU[0] !== undefined) {
          PLUCode = item.ProductPLU[0].PLUCode
        }
        
        const category = item.category || {}
        const categoryName = category.name || 'N/A'
        
        const SKUName = item.SKUName || ''

        detail.push({
          'id': start + i + 1,
          'PLUCode': PLUCode,
          'SKUName': SKUName,
          'categoryName': categoryName,
          'stdCost': util.convertCurrency(stdCost),
          'onhandQty': onhandQty,
        })
      }
      return detail
    },
  },
  created() {
    this.getUnsellReport()
  },
  methods: {
    setSort(value) {
      this.sortdata = value
      this.getUnsellReport()
    },
    getUnsellReport(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      if (this.sortdata == 'sortByNumMin' || this.sortdata == 'onhandQtyMin') {
        sortOrder = 'asc'
      } else if (
        this.sortdata == 'sortByNumMax' ||
        this.sortdata == 'onhandQtyMax'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
        sortattr: this.sortAttr,
        sortorder: sortOrder,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/producttransaction/v1.0/getunsell/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportUnsellReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/producttransaction/v1.0/getunsell/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสินค้าที่ไม่มีการขาย.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
